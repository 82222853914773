import Vue from 'vue'
import PaymentForm from '../payment-form.vue'

document.addEventListener('turbolinks:load', () => {
  const el = document.getElementById("payments");

  if(el) {
      const app = new Vue({
        el,
        render: h => h(PaymentForm)
      }).$mount(el)
  }
})

