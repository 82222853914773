<template>
  <div>
    <h1>Make A Payment</h1>

    <div class="radio">
      <input type="radio" id="cc" value="stripe" v-model="paymentType">
      <label for="cc">Credit Card</label>
      <br>
      <input type="radio" id="paypal" value="paypal" v-model="paymentType">
      <label for="paypal">PayPal</label>
    </div>


    <p v-if="paymentType == 'stripe'">Please note that there is a 3% surcharge automatically added on all credit card transaction fees.</p>
    <div v-if="processing" class="processing">
      Processing...
    </div>
    <div v-if="!charge.paid && paymentType != ''" :class="{dim: processing}">
      <p>First, enter a your invoice number so we can apply your payment to the correct account. Then enter the amount you want to pay.</p>
      <form @submit="onSubmit" action="/payments" method="post" id="payment-form">
        <div class="form-row">
          <label for="">Invoice Number:</label>
          <input v-model="payment.reference"type="text" name="ref_number" placeholder="Invoice Number" value="" />
        </div>
        <div class="form-row">
          <label for="">Amount:</label>
          <input v-model="payment.amount_to_apply" type="number" step=".01" name="ref_number" placeholder="Amount to pay" />
          <div class="line-items">
            <div v-if="paymentType == 'stripe'">
              Amount to apply to account: <span class="currency">${{currency(payment.amount_to_apply)}}</span> 
            </div>
            <div v-if="paymentType == 'stripe'">
              Processing Fee ({{processingFeePercent}}): <span class="currency">${{currency(payment.fee)}}</span>
            </div>
            <div>
              Charge Amount: <span class="currency"><strong>${{currency(payment.amount)}}</strong></span>
            </div>
          </div>
        </div>
        <div v-show="validPayment">
          <div class="form-row">
            <div v-show="paymentType == 'stripe'" class="row">
              <div class="col-md-6">
                <div id="card-element"></div>
                <div id="card-errors" role="alert"></div>
              </div>
            </div>
            <div v-show="paymentType == 'paypal'" class="row">
              <div class="col-md-2">
                <div id="paypal-button-container"></div>
              </div>
            </div>
          </div>
          <p v-if="paymentType == 'stripe'" style="padding-top: 15px;"><button :disabled="!payment.card_complete"class="button button--tertiary">Submit Payment</button></p>
        </div>
      </form>
    </div>

    <div v-if="charge.paid" class="receipt">
      <div class="section-header text--center">
        <p>Success! Here is your receipt...</p>
        <h2>Receipt</h2>
        <div class="row">
          <div class="col-md-4">
            <label for="">Amount Charged</label>
            {{charge.amount}}
          </div>
          <div class="col-md-4">
            <label for="">Date Paid</label>
            {{charge.date}}
          </div>
          <div class="col-md-4">
            <label for="">Payment Method</label>
            {{charge.payment_method}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "api";
import { round  } from 'lodash';
export default {
  data() {
    return {
      stripe: null,
      card: null,
      paymentType: '',
      processing: false,
      payment: {
        reference: '',
        amount: 0,
        fee: 0,
        charge_id: '',
        amount_to_apply: 0,
        stripeToken: '',
        paid_thru: 'stripe',
        card_complete: false
      },
      charge: {paid: false},
      processingFee: 0.03,
      processingFeePercent: "3%"
    }
  },
  watch: {
    "payment.amount_to_apply": function() {
      this.calculateFee();
    },
    paymentType() {
      if(this.validPayment) {
        this.calculateFee();
      }
    },
    validPayment() {
      if(this.validPayment) {
        this.initPayPal()
        this.initElement()
      }
    }
  },
  computed: {
    validPayment() {
      return this.payment.amount > 0 && !!this.payment.reference
    }
  },
  methods: {
    calculateFee() {
      let initial = parseFloat(this.payment.amount_to_apply);
      if(this.paymentType == 'paypal') {
        this.payment.fee = 0
        this.payment.amount = initial
      } else {
        this.payment.fee = _.round(initial * this.processingFee, 2)
        this.payment.amount = _.round(initial + this.payment.fee, 2)
      }
    },
    currency(num) {
      return parseFloat(num).toLocaleString();
    },
    save() {
      Api().post("/payments", {
        payment: this.payment
      })
      .then((response) => {
        this.charge = response.data.charge
        this.processing = false
      })
      .catch(error => {
        alert(error)
        this.processing = false
      })
    },
    onSubmit(e) {
      e.preventDefault();
      this.processing = true
      if(this.payment.paid_thru != 'paypal') {
        this.stripe.createToken(this.card).then((result) => {
          if (result.error) {
            var errorElement = document.getElementById('card-errors');
            errorElement.textContent = result.error.message;
          } else {
            this.payment.stripeToken = result.token.id
            this.save();
          }
        })
      } else {
        this.save()
      }
    },
    initPayPal() {
      let t = this
      paypal.Buttons({
        createOrder: function(data, actions) {
          t.processing = true
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: t.payment.amount
              }
            }]
          });
        },
        onApprove: function(data, actions) {
          t.payment.paid_thru = 'paypal'
          t.payment.charge_id = data.orderID
          actions.order.capture().then(function(details) {
            if(details.status == "COMPLETED") {
              t.processing = false
              t.save()
            } else {
              alert("There was a problem capturing your payment.")
            }
          });
        }
      }).render('#paypal-button-container');
    },
    initElement() {
      let t = this
      const elements = this.stripe.elements();
      const style = {
        base: {
          fontSize: '16px',
          color: "#32325d",
        },
      };

      this.card = elements.create('card', {style});
      this.card.addEventListener('change', function(event) {
        if(event.complete) {
          t.payment.card_complete = true
        }
      });
      this.card.mount('#card-element');
    }
  },
  mounted() {
    this.stripe = Stripe(process.env.STRIPE_PK || 'pk_live_cNUIeaSlvKhDGST8D91AnSXL000DoN51c0');
  }
}
</script>

<style lang="scss" scoped>
input[type='text'], input[type='number'] {
  font-size: 16px;
  border: 1px solid;
  width: 350px;
  margin-bottom: 20px;
  margin-top: 10px;
}
label {
  display: block;
}

.radio label {
  display: inline;
}

span.currency {
  float: right;
}
.line-items {
  margin-bottom: 2em;
  div {
    width: 100%;
    max-width: 350px;
  }
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.form-row {
}
  .receipt {
    width: 70%;
    margin: 50px auto;
    background: #f1f1f1;
    padding: 30px 10px;
    box-shadow: 5px 6px 5px 2px #00000014;

  label {
    font-weight: bold;
    margin-bottom: 10px;
  }
}
.processing {
position: absolute;
z-index: 10;
background: #cddd297a;
width: 100%;
text-align: center;
padding: 30px;
color: black;
font-size: 20px;
text-transform: uppercase;
top: 10%;
}
.dim {
opacity: 0.3;
}
.receipt {
}
</style>
